import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CloseIcon from '../../icons/close'
import * as styles from './menu-modal.module.scss'
import LinksByCategory from '../LinksByCategory/LinksByCategory'
import MenuRight from '../MenuRight/MenuRight'

export default function MenuModal(props) {
  const modalRef = useRef()

  useEffect(() => {
    if (props.show) {
      modalRef.current.classList.add('d-block', 'overflow-hidden')
      document.body.classList.add('overflow-hidden')
      setTimeout(() => modalRef.current.classList.add(styles.show), 100)
      setTimeout(() => modalRef.current.classList.remove('overflow-hidden'), 500)
    } else {
      document.body.classList.remove('overflow-hidden')
      setTimeout(() => modalRef.current.classList.remove('d-block'), 600)
      modalRef.current.classList.remove(styles.show)
    }
  }, [props.show])

  return (
    <div className={styles.menuModal} ref={modalRef}>
      <button onClick={props.onCloseModal} className={classNames('btn', styles.closeButton)}>
        <CloseIcon />
      </button>
      <div className={styles.menuContent}>
        <div className='container'>
          <div className="row">
            <div className="col-12 col-lg-9">
              <LinksByCategory />
            </div>
            <div className='col-12 col-lg-3'>
              <MenuRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MenuModal.propTypes = {
  show: PropTypes.bool,
  onCloseModal: PropTypes.func,
}

MenuModal.defaultProps = {
  show: false,
  onCloseModal: () => {}
}
