import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import MenuIcon from '../icons/menu'
import * as styles from './menu.module.scss'
import MenuModal from './MenuModal/MenuModal'

const Menu = ({ intl }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <button className={classNames('btn px-0', styles.menu)} onClick={() => setShow(!show)}>
        <span className={classNames(styles.menuText, 'd-none d-md-inline')}>
          {intl.formatMessage({ id: 'header.menu.title' })}
        </span>
        <MenuIcon className={styles.menuIcon} />
      </button>
      <MenuModal show={show} onCloseModal={() => setShow(false)} />
    </>
  )
}

Menu.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(Menu)
