import React from 'react'
import { Link, useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import * as styles from './newsletter.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import useLink from '../../../hooks/useLink'
import SocialMediaIcon from '../../common/SocialMediaIcon/SocialMediaIcon'

export default function Newsletter() {
  const intl = useIntl()
  const { buildURL } = useLink()

  const data = useStaticQuery(graphql`
    {
      allContentfulPage(filter: { pageType: { eq: "Contacts" } }) {
        edges {
          node {
            ...PageBasicInformation
          }
        }
      }
      socialMedia: allContentfulReseauxSociaux(filter: { displayOnFooter: { eq: true } }) {
        edges {
          node {
            id: contentful_id
            link
            name
            displayOnFooter
            displayOnHeader
            node_locale
          }
        }
      }
      legalPages: allContentfulPage(filter: { pageType: { eq: "Légale" } }) {
        edges {
          node {
            ...PageBasicInformation
          }
        }
      }
    }
  `)

  const contactPages = data.allContentfulPage.edges
    .filter(e => e.node.node_locale.startsWith(intl.locale))
    .map(e => e.node)
  const socialMedia = data.socialMedia.edges
    .filter(e => e.node.node_locale.startsWith(intl.locale))
    .map(e => ({ ...e.node, name: e.node.name.toLowerCase() }))
  const legalPages = data.legalPages.edges
    .filter(e => e.node.node_locale.startsWith(intl.locale))
    .map(e => e.node)

  return (
    <div
      style={{
        backgroundColor: '#211b25',
        backgroundImage:
          'url("https://images.unsplash.com/photo-1601469502319-6834b857a87d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80")',
      }}
      className="py-3 py-md-5 with-overlay"
    >
      <div className="overlay" />
      <div className="content">
        <div className={classNames(['container'], styles.newsletter)}>
          <div className="text-center mb-5">
            <h2 className={styles.newsletterTitle}>
              {intl.formatMessage({ id: 'newsletter.newsletter' })}
            </h2>
            <div>
              <Link
                to={buildURL({
                  pageType: 'Actualité',
                  __typename: 'ContentfulPage',
                  slug: 'newsletter',
                })}
                rel="nofollow"
                className={classNames('btn btn-outline-light', styles.newsletterButton)}
              >
                {intl.formatMessage({ id: 'newsletter.join-newsletter' })}
              </Link>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-between mb-3 mb-md-5">
            <div className={classNames('mb-3', styles.mw400)}>
              <h3 className={styles.subtitle}>
                {intl.formatMessage({ id: 'newsletter.contact-and-access' })}
              </h3>
              <div className="row">
                <div className="col-12 mb-3 mb-md-4">
                  <Link to="/contacts" rel="nofollow" className="btn btn-outline-light d-block">
                    Contacts
                  </Link>
                </div>
                {contactPages.map(contactPage => (
                  <div
                    className={classNames('col-12 mb-4', { 'col-md-6': contactPages.length > 1 })}
                    key={contactPage.id}
                  >
                    <Link
                      to={buildURL(contactPage)}
                      rel="nofollow"
                      className="btn btn-outline-light d-block"
                    >
                      {contactPage.title}
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div className="mb-3">
              <h3 className={styles.subtitle}>
                {intl.formatMessage({ id: 'newsletter.follow-us' })}
              </h3>
              <ul className={classNames(styles.socialMediaList, 'list-unstyled')}>
                {socialMedia.map(s => (
                  <li key={s.id}>
                    <a
                      href={s.link}
                      rel="nofollow noopener noreferrer"
                      title={s.name}
                      target="_blank"
                      className={classNames(styles.socialMediaIcon, styles[s.name])}
                    >
                      <SocialMediaIcon name={s.name} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <ul className={classNames(['list-unstyled'], styles.menuItemList)}>
            {legalPages.map(page => (
              <li className={styles.menuItem} key={page.id}>
                <Link to={buildURL(page)}>{page.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
