import React from 'react'
import MenuCategory from '../MenuCategory/MenuCategory'
import { useIntl } from 'gatsby-plugin-intl'
import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'

export default function LinksByCategory() {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    {
      allContentfulMenuCategorie {
        edges {
          node {
            id: contentful_id
            pages {
              id: contentful_id
              title
              slug
              __typename
              pageType
              category
            }
            node_locale
            title
          }
        }
      }
    }
  `)

  const categories = get(data, 'allContentfulMenuCategorie.edges', [])
    .map(e => e.node)
    .filter(category => category.node_locale.startsWith(intl.locale) && category.pages?.length)

  return (
    <div className="row">
      {categories.map(category => (
        <div className={'col-12 col-lg-4'} key={category.id}>
          <MenuCategory category={category} />
        </div>
      ))}
    </div>
  )
}
