import React from 'react'

const FacebookIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="0.49em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 486.037 1000"
    {...props}
  >
    <path
      d="M124.074 1000V530.771H0V361.826h124.074V217.525C124.074 104.132 197.365 0 366.243 0C434.619 0 485.18 6.555 485.18 6.555l-3.984 157.766s-51.564-.502-107.833-.502c-60.9 0-70.657 28.065-70.657 74.646v123.361h183.331l-7.977 168.945H302.706V1000H124.074"
      fill="currentColor"
    />
  </svg>
)

export default FacebookIcon
