import * as React from 'react'
import PropTypes from 'prop-types'
import { Link, useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import Logo from '../../../assets/images/logo.svg'
import Language from '../../widgets/Language'
import * as styles from './header.module.scss'
import Search from '../Search'
import Menu from '../Menu'
import { graphql, useStaticQuery } from 'gatsby'
import SocialMediaIcon from '../SocialMediaIcon/SocialMediaIcon'

const Header = ({ location }) => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    {
      socialMedia: allContentfulReseauxSociaux(filter: { displayOnHeader: { eq: true } }) {
        edges {
          node {
            id: contentful_id
            link
            name
            displayOnFooter
            displayOnHeader
            node_locale
          }
        }
      }
    }
  `)
  const socialMedia = data.socialMedia.edges
    .filter(e => e.node.node_locale.startsWith(intl.locale))
    .map(e => ({ ...e.node, name: e.node.name.toLowerCase() }))

  return (
    <header className={classNames(styles.header, ['sticky-top'])} role="banner">
      <section className={classNames(styles.headerTop, 'd-flex')}>
        <div
          className={classNames([
            'container',
            'd-flex',
            'align-items-center',
            'justify-content-between',
          ])}
        >
          <Language location={location} />
          <div className={styles.socialMediaLinks}>
            {socialMedia.map(s => (
              <a
                className={classNames(styles.socialMediaLink, styles[s.name])}
                href={s.link}
                target="_blank"
                rel="noreferrer"
                key={s.id}
              >
                <SocialMediaIcon name={s.name} />
              </a>
            ))}
          </div>
        </div>
      </section>
      <section className={classNames(styles.headerBottom, 'd-flex')}>
        <div
          className={classNames([
            'container',
            'd-flex',
            'align-items-center',
            'justify-content-between',
          ])}
        >
          <div>
            <Link to="/">
              <img className={styles.logo} src={Logo} height="100" alt={'Logo'} />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <Search />
            <Menu />
          </div>
        </div>
      </section>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  location: PropTypes.object,
}

Header.defaultProps = {
  siteTitle: '',
  location: '',
}

export default Header
