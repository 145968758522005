const _ = require('lodash')
const { TYPES, CATEGORIES, CONTENTFUL_ENTRY_TYPES } = require('./constants')

const buildRootURL = (page, locale = 'fr') => {
  // const contentType = getContentType(page)
  if (page.__typename === CONTENTFUL_ENTRY_TYPES.PAGE) {
    const type = _.find(TYPES, { key: page.pageType }) || { url: { [locale]: '' } }
    const categorie = _.find(CATEGORIES, { key: page.category }) || { url: { [locale]: '' } }
    return type.url[locale] + categorie.url[locale]
  }

  if (page.__typename === CONTENTFUL_ENTRY_TYPES.PAGE_EVENT) {
    const category = _.find(CATEGORIES, { key: page.type }) || { url: { [locale]: '' } }
    return TYPES[0].url[locale] + category.url[locale]
  }
  return ''
}

const buildURL = (page, locale = 'fr') => {
  return buildRootURL(page, locale) + '/' + page.slug
}

module.exports = { buildURL, buildRootURL }
