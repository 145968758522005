import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import SearchIcon from '../icons/search'
import * as styles from './search.module.scss'

const Search = ({ intl }) => (
  <button className={classNames('btn px-0', styles.search)}>
    <SearchIcon className={styles.searchIcon} />
    <span className={classNames(styles.searchText, 'd-none d-md-inline')}>{intl.formatMessage({id: 'header.search.title'})}</span>
  </button>
)

Search.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(Search)