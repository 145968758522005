import React from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'
import './Language.scss'

const Language = ({location}) => (
  <ul className="languages" role="navigation">
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => (
          <li
            key={language}
            onClick={() => changeLocale(language)}
            className={`language-item ${currentLocale === language ? 'is-active' : ''}`}
          >
            {
              currentLocale === language ? 
                <span className="language-link">{language}</span> 
                : 
                <a className="language-link switcher" href={location.pathname.replace(currentLocale, language)} role="button">{language}</a>
            }
          </li>
        ))
      }
    </IntlContextConsumer>
  </ul>
)

export default Language
