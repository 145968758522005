import React from 'react'
import Newsletter from '../../sections/Newsletter/Newsletter'

export default function Footer() {
  return (
    <footer>
      <Newsletter />
    </footer>
  )
}
