import React from 'react'
import { Link, useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import * as styles from './menu-right.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import useLink from '../../../../hooks/useLink'

export default function MenuRight() {
  const intl = useIntl()
  const { buildRootURL } = useLink()

  const data = useStaticQuery(graphql`
    {
      menuRight: allContentfulMenuElementsDeLaPartieDroite {
        edges {
          node {
            title
            node_locale
            id: contentful_id
            examplePage {
              ... on ContentfulPage {
                id: contentful_id
                pageType
                __typename
              }
              ... on ContentfulPageEvenement {
                id: contentful_id
                __typename
              }
            }
            icon {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `)
  const items = data.menuRight.edges
    .filter(e => e.node.node_locale.startsWith(intl.locale))
    .map(e => e.node)

  return (
    <ul className={classNames('list-unstyled', styles.menuRight)}>
      {items.map(item => (
        <li className={'mb-2 mb-md-3'} key={item.id}>
          <Link className={styles.link} to={buildRootURL(item.examplePage)}>
            <img src={item.icon.file.url} alt={item.icon.title} className={styles.icon} />
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}
