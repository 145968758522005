import * as React from 'react'

const MenuIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect x="0" y="0" width="24" height="24" fill="none" stroke="none" />
    <g fill="none">
      <path d="M21 18H3v-2h18v2zm0-5H3v-2h18v2zm0-5h-9V6h9v2z" fill="currentColor" />
    </g>
  </svg>
)

export default MenuIcon
