// extracted by mini-css-extract-plugin
export var menuItemList = "newsletter-module--menu-item-list--s3vmD";
export var menuItem = "newsletter-module--menu-item--fAZbD";
export var newsletter = "newsletter-module--newsletter--VZWks";
export var socialMediaIcon = "newsletter-module--social-media-icon--vD-BR";
export var facebook = "newsletter-module--facebook--KrBY5";
export var instagram = "newsletter-module--instagram--nc4Yu";
export var linkedin = "newsletter-module--linkedin--hHcag";
export var youtube = "newsletter-module--youtube--0w9TG";
export var twitter = "newsletter-module--twitter--bD2BS";
export var pinterest = "newsletter-module--pinterest--4EITV";
export var socialMediaList = "newsletter-module--social-media-list--D94pg";
export var subtitle = "newsletter-module--subtitle--JS-NL";
export var newsletterTitle = "newsletter-module--newsletter-title--alZEz";
export var newsletterButton = "newsletter-module--newsletter-button--rtLy7";
export var mw400 = "newsletter-module--mw400--3GqMb";