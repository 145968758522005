import React from 'react'
import PropTypes from 'prop-types'
import FacebookIcon from '../icons/facebook'
import TwitterIcon from '../icons/twitter'
import InstagramIcon from '../icons/instagram'
import PinterestIcon from '../icons/pinterest'
import LinkedinIcon from '../icons/linkedin'
import YoutubeIcon from '../icons/youtube'

export default function SocialMediaIcon(props) {
  switch (props.name) {
    case 'facebook':
      return <FacebookIcon {...props} />
    case 'twitter':
      return <TwitterIcon {...props} />
    case 'instagram':
      return <InstagramIcon {...props} />
    case 'pinterest':
      return <PinterestIcon {...props} />
    case 'linkedin':
      return <LinkedinIcon {...props} />
    case 'youtube':
      return <YoutubeIcon {...props} />
    default:
      return <></>
  }
}

SocialMediaIcon.propTypes = {
  name: PropTypes.string.isRequired,
}
