import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import * as styles from './menu-category.module.scss'
import useLink from '../../../../hooks/useLink'

export default function MenuCategory(props) {
  const { category } = props
  const { buildURL, buildRootURL } = useLink()

  return (
    <div className={'pb-3'}>
      <h4 className={'mb-3 mb-md-4 text-uppercase'}>
        <Link to={buildRootURL(category.pages[0])}>{category.title}</Link>
      </h4>
      <ul className="list-unstyled">
        {category.pages.map(page => (
          <li className={classNames('mb-3', styles.listItem)} key={page.id}>
            <Link to={buildURL(page)}>{page.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

MenuCategory.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
}
