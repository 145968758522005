const TYPES = [
  {
    key: 'Évènement',
    url: { fr: '/evenement', en: '/event' },
  },
  {
    key: 'Actualité',
    url: { fr: '/actualite', en: '/news' },
  },
  {
    key: 'Domaine',
    url: { fr: '/domaine', en: '/activity-domain' },
  },
  {
    key: 'Projet',
    url: { fr: '/projet', en: '/project' },
  },
  {
    key: 'Référence',
    url: { fr: '/reference', en: '/reference' },
  },
  {
    key: 'Solution',
    url: { fr: '/solution', en: '/solution' },
  },
  {
    key: 'Identité',
    url: { fr: '/identite', en: '/identity' },
  },
  {
    key: 'Contacts',
    url: { fr: '/contacts', en: '/contacts' },
  },
  {
    key: 'Légale',
    url: { fr: '/legal', en: '/legal' },
  },
]

const CATEGORIES = [
  {
    key: 'Actualité',
    url: { fr: '/actualite', en: '/news' },
  },
  {
    key: 'Communiqué de presse',
    url: { fr: '/communique-de-presse', en: '/communique-de-presse' },
  },
  {
    key: 'Vidéo',
    url: { fr: '/video', en: '/video' },
  },
  {
    key: 'Conférence',
    url: { fr: '/conference', en: '/conference' },
  },
  {
    key: 'Exposition',
    url: { fr: '/exposition', en: '/exposition' },
  },
  {
    key: 'Salon',
    url: { fr: '/salon', en: '/salon' },
  },
]

const CONTENTFUL_ENTRY_TYPES = {
  PAGE: 'ContentfulPage',
  PAGE_EVENT: 'ContentfulPageEvenement',
}

module.exports = {
  TYPES,
  CATEGORIES,
  CONTENTFUL_ENTRY_TYPES,
}
