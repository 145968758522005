import { useIntl } from 'gatsby-plugin-intl'
import { graphql, useStaticQuery } from 'gatsby'
import { buildURL as utilBuildURL, buildRootURL as utilBuildRootURL } from '../utils/url'

const query = graphql`
  {
    allContentfulPage {
      group(field: contentful_id) {
        edges {
          node {
            id: contentful_id
            node_locale
            pageType
            slug
            category
            __typename
          }
        }
      }
    }
    allContentfulPageEvenement {
      group(field: contentful_id) {
        edges {
          node {
            id: contentful_id
            node_locale
            slug
            type
            __typename
          }
        }
      }
    }
  }
`

export default function useLink() {
  const intl = useIntl()
  const buildURL = (page, locale = intl.locale) => utilBuildURL(page, locale)
  const buildRootURL = (page, locale = intl.locale) => utilBuildRootURL(page, locale)
  return { buildURL, buildRootURL }
}
